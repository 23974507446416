import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
// import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import FeaturePopup from "./FeaturePopup";
import useFeature from "./useFeature";
import './FeatureMap.scss';
import './toastPopup.scss';
import { setFile } from '../../../store/GeofeatureSlice'; // Import Redux action

import JSZip from 'jszip';
import { useDispatch } from 'react-redux';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
function DrawFeature({ onFileSelect }) {
  const [promptPosition, setPromptPosition] = useState({ x: 0, y: 0 });
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptEdit, setShowPromptEdit] = useState(false);
  const [coordie, setCoordie] = useState([]);
  const [polyDetails, setPolyDetails] = useState([]);
  const [featureId, setFeatureId] = useState(null);
  const [pointCoordie, setPointCoordie] = useState(null);
  const [pointDetails, setPointDetails] = useState([]);
  const [currentFeature, setCurrentFeature] = useState('');
  const [aoiFeatureData, setAoiFeatureData] = useState()
  const [editedTitle, setEditedTitle] = useState("")
  const [editedDescription, setEditedDescription] = useState("")
  const [updatedCoordinates, setUpdatedCoordinates] = useState()
  const [disableUploadButton, setDisableUploadButton] = useState(false)
  const [activeUploadButton, setActiveUploadButton] = useState(false)
  const [delayedHover, setDelayedHover] = useState(false);
  const [hover, setHover] = useState(false)
  const { postFeatureData, updateFeatureData, getAoiData } = useFeature()
  const params = useParams();
  const { mission_name, mission_id } = params
  const navigate = useNavigate();
  const location = useLocation();
  const { coordinates, aoi_id, titleEdit, descriptionEdit, featuretype, editmode } = location.state || {};
  const [titleInPopup, setTitleInPopup] = useState(titleEdit)
  const [descriptionInPopup, setDescriptionInPopup] = useState(descriptionEdit)
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const draw = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();


  const disableButton = (buttonName) => {
    const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
    const pointButton = document.querySelector('.mapbox-gl-draw_point');
    const trashButton = document.querySelector('.mapbox-gl-draw_trash');

    if (buttonName === 'point') {
      if (pointButton) {
        pointButton.disabled = true;
        pointButton.style.opacity = '0.5';
        pointButton.style.cursor = 'not-allowed';
      }
    } else if (buttonName === 'polygon') {
      if (polygonButton) {
        polygonButton.disabled = true;
        polygonButton.style.opacity = '0.5';
        polygonButton.style.cursor = 'not-allowed';
      }
    } else if (buttonName === 'trash') {
      if (trashButton) {
        trashButton.disabled = true;
        trashButton.style.opacity = '0.5';
        trashButton.style.cursor = 'not-allowed';
      }
    }
  }

  const enableButton = (buttonName) => {
    const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
    const pointButton = document.querySelector('.mapbox-gl-draw_point');
    const trashButton = document.querySelector('.mapbox-gl-draw_trash');

    if (buttonName === 'point') {
      if (pointButton) {
        pointButton.disabled = false;
        pointButton.style.opacity = '1';
        pointButton.style.pointerEvents = 'auto';
        pointButton.style.cursor = 'pointer';
      }
    } else if (buttonName === 'polygon') {
      if (polygonButton) {
        polygonButton.disabled = false;
        polygonButton.style.opacity = '1';
        polygonButton.style.pointerEvents = 'auto';
        polygonButton.style.cursor = 'pointer';
      }
    } else if (buttonName === 'trash') {
      if (trashButton) {
        trashButton.disabled = false;
        trashButton.style.opacity = '1';
        trashButton.style.pointerEvents = 'auto';
        trashButton.style.cursor = 'pointer';
      }
    }
  }

  const updatePromptPositionForPolygon = (coordinates) => {
    const rectangle = coordinates.reduce((acc, coord) => {
      return {
        minX: Math.min(acc.minX, coord[0]),
        maxX: Math.max(acc.maxX, coord[0]),
        minY: Math.min(acc.minY, coord[1]),
        maxY: Math.max(acc.maxY, coord[1])
      };
    }, { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity });

    const x = rectangle.maxX + 10;
    const y = (rectangle.minY + rectangle.maxY) / 2;
    const point = map.current.project([x, y]);
    const canvasWidth = map.current.getCanvas().clientWidth;
    const canvasHeight = map.current.getCanvas().clientHeight;
    const promptWidth = 320;
    const promptHeight = 246; // Assuming a fixed height for the prompt
    // Check if there's enough space on the right
    if ((canvasWidth - point.x) < promptWidth) {
      const min_points = map.current.project([rectangle.minX, rectangle.minY]);
      // Not enough space on the right, try left
      if (min_points.x - promptWidth > 0) {
        point.x = min_points.x - promptWidth - 30; // Shift to the left
      } else {
        // Not enough space on either side, try above or below
        const midX = (rectangle.minX + rectangle.maxX) / 2;
        const midY = (rectangle.minY + rectangle.maxY) / 2;
        const centerPoint = map.current.project([midX, midY]);

        // Check if there's enough space above the polygon
        if (centerPoint.y - promptHeight > 0) {
          point.x = centerPoint.x - promptWidth / 2;
          point.y = centerPoint.y - promptHeight - 20;
        }
        // Check if there's enough space below the polygon
        else if (centerPoint.y + promptHeight + 20 < canvasHeight) {
          point.x = centerPoint.x - promptWidth / 2;
          point.y = centerPoint.y + 20;
        } else {
          // Fallback: Place the prompt at the top-left corner if no space above/below
          point.x = 20;
          point.y = 20;
        }
      }
    }
    // Adjust for vertical boundaries (top and bottom)
    if (point.y < 50) {
      point.y = 50; // Set a minimum y-value to avoid going off-screen at the top
    } else if (point.y + promptHeight > canvasHeight) {
      point.y = canvasHeight - promptHeight - 20; // Prevent the prompt from going off the bottom of the screen
    }
    setPromptPosition({ x: point.x - 10, y: point.y + 60 });
  }

  const updatePromptPositionForPoint = (coordinates) => {
    const x = coordinates[0]; // Longitude
    const y = coordinates[1]; // Latitude
    const point = map.current.project([x, y]);
    const canvasWidth = map.current.getCanvas().clientWidth;
    const canvasHeight = map.current.getCanvas().clientHeight;
    const promptWidth = 320;
    const promptHeight = 246; // Assuming a fixed height for the prompt
    if ((canvasWidth - point.x) < promptWidth) { // Check if there's enough space on the right
      point.x = point.x - promptWidth - 50; // Shift to the left
    } else if (point.x < 50) {
      // If too close to the left edge
      point.x = 50; // Adjust to stay within left boundary
    }
    // Check for vertical boundaries (top and bottom)
    if (point.y < 50) {
      if (point.y < 0) {
        point.y = point.y + 600;
      } else {
        point.y = point.y + 47;
      }
      // Move down if too close to the top
    } else if (point.y > (canvasHeight - promptHeight - 20)) {
      point.y = canvasHeight - promptHeight - 20; // Adjust to stay within bottom boundary
    }
    setPromptPosition({ x: point.x + 20, y: point.y + 60 });
  }

  //for adding into array
  const handleData = (title, desc) => {
    // const polyCount = polyDetails.length + 1;
    // const pointCount = pointDetails.length + 1;
    const polycount = parseInt(sessionStorage.getItem('allaoilengthpoly'), 10) || 0;
    const pointcount = parseInt(sessionStorage.getItem('allaoilengthpoint'), 10) || 0;
    if (currentFeature === 'Polygon' && (title === '' || title === undefined)) {
      title = `Poly ${polycount + 1}`
      sessionStorage.setItem('allaoilengthpoly', polycount + 1)
      // title = polyDetails.length === 0 ? 'Poly 1' : `Poly ${polyCount}`;
    }
    if (currentFeature === 'Point' && (title === '' || title === undefined)) {
      // title = pointDetails.length === 0 ? 'Point 1' : `Point ${pointCount}`;
      title = `Point ${pointcount + 1}`
      sessionStorage.setItem('allaoilengthpoint', pointcount + 1)
    }
    if (currentFeature === 'Polygon') {
      setPolyDetails((prevDetails) => {
        const existingDetailIndex = prevDetails.findIndex((detail) => detail.id === featureId);
        if (existingDetailIndex !== -1) {
          const updatedDetails = [...prevDetails];
          updatedDetails[existingDetailIndex] = {
            ...updatedDetails[existingDetailIndex],
            title, description: desc, coordinates: coordie
          };
          return updatedDetails;
        } else {
          return [
            ...prevDetails,
            { title, description: desc, coordinates: coordie, id: featureId, uuid: uuidv4(), type: 'Geofence' }
          ];
        }
      });
      // enableButton('polygon')
      disableButton('point')
      // enableButton('trash')
    }
    else {
      setPointDetails((prevDetails) => {
        const existingDetailIndex = prevDetails.findIndex((detail) => detail.id === featureId);
        if (existingDetailIndex !== -1) {
          const updatedDetails = [...prevDetails];
          updatedDetails[existingDetailIndex] = {
            ...updatedDetails[existingDetailIndex],
            title, description: desc, coordinates: pointCoordie
          };
          return updatedDetails;
        } else {
          return [
            ...prevDetails,
            { title, description: desc, coordinates: pointCoordie, id: featureId, uuid: uuidv4(), type: 'Target Track' }
          ];
        }
      });
      // enableButton('point')
      disableButton('polygon')
      // enableButton('trash')
    }
  };

  const showPopupOnEdit = async () => {
    if (coordinates) {
      try {
        const aoiData = await getAoiData(aoi_id); // Ensure this is within an async function
        setAoiFeatureData(aoiData); // Set the fetched data

        // Check if lat and long are defined
        // if (coordinates.lat !== undefined && coordinates.long !== undefined) {
        //   updatePromptPositionForPoint([coordinates.long, coordinates.lat]);
        // } else {
        //   updatePromptPositionForPolygon(coordinates);
        // }

        // setShowPromptEdit(true);
      } catch (error) {
        console.error("Error fetching AOI data:", error);
      }
    }
    // else {
    //   setShowPromptEdit(false);
    // }
  };



  const handleUpload = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      // toast.dismiss();
      // toast.error("No file selected", { className: 'custom-toast-error', position: 'top-right' }, { toastId: '0' });
      return;
    }

    const fileSizeLimit = 1048576; // 1 MB in bytes
    if (file.size > fileSizeLimit) {
      toast.dismiss();
      toast.error("File exceeds the size limit (1 MB)", { className: 'custom-toast-error align__toast__position', position: 'top-right' });
      event.target.value = '';
      return;
    }

    const fileName = file.name.toLowerCase();

    // Common function to handle all file types
    const processFile = (arrayBuffer) => {
      dispatch(setFile({ arrayBuffer, fileName: file.name })); // Dispatch the file and fileName to Redux
      navigate(`${process.env.PUBLIC_URL}/${mission_name}/${mission_id}/shape_feature`);
    };

    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result; // Accessing the ArrayBuffer after reading the file

      // Check for file types and process accordingly
      if (fileName.endsWith('.zip')) {
        //checking if it is actually a zip file
        function isZipFile(arrayBuffer) {
          const signature = new Uint8Array(arrayBuffer.slice(0, 4));
          return signature[0] === 0x50 && signature[1] === 0x4b && signature[2] === 0x03 && signature[3] === 0x04;
        }

        if (isZipFile(arrayBuffer)) {
          JSZip.loadAsync(arrayBuffer).then((zip) => {
            zip.forEach((relativePath, zipEntry) => {
              // You can process the shapefile here if needed
            });
          }).catch((error) => {
            console.error("Failed to load ZIP file:", error);
          });
        } else {
          console.error("Uploaded file is not a valid ZIP archive.");
        }
        processFile(arrayBuffer); // Dispatch ZIP file
      } else if (fileName.endsWith('.geojson') || fileName.endsWith('.json')) {
        processFile(arrayBuffer); // Handle GeoJSON
      } else if (fileName.endsWith('.topojson')) {
        processFile(arrayBuffer); // Handle TopoJSON
      } else if (fileName.endsWith('.csv')) {
        processFile(arrayBuffer); // Handle CSV
      } else if (fileName.endsWith('.kml')) {
        processFile(arrayBuffer); // Handle KML
      } else if (fileName.endsWith('.wkt')) {
        processFile(arrayBuffer); // Handle WKT
      } else {
        toast.dismiss();
        toast.error("File format not supported", { className: 'custom-toast-error align__toast__position', position: 'top-right' }, { toastId: '0' });
      }
    };

    // Check for specific file types and read them as ArrayBuffer
    if (fileName.endsWith('.zip') || fileName.endsWith('.geojson') || fileName.endsWith('.json') ||
      fileName.endsWith('.topojson') || fileName.endsWith('.csv') || fileName.endsWith('.kml') ||
      fileName.endsWith('.wkt')) {
      reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer
    } else {
      toast.dismiss();
      toast.error("File format not supported", { className: 'custom-toast-error align__toast__position', position: 'top-right' }, { toastId: '0' });
    }
  };



  //converting data into api format
  //for polygon
  const transformPolyData = (polyDetails) => {
    const areaOfInterests = [
      ...polyDetails.map(detail => ({
        "name": detail.title,
        "type": detail.type,
        "description": detail.description,
        "coordinates": {
        },
        "polygon": detail.coordinates[0],
      }))
    ];

    return {
      areaOfInterests,
      "name": `AOI Manager ${Number(sessionStorage.getItem('aoiManagerLength'))}`,
      "type": polyDetails.length > 1 ? "Collection" : "Single"
    };
  };

  //for point
  const transformPointData = (pointDetails) => {
    const areaOfInterests = [
      ...pointDetails.map(detail => ({
        "name": detail.title,
        "type": detail.type,
        "description": detail.description,
        "coordinates": {
          "altitude": null,
          "lat": detail.coordinates[1],
          "long": detail.coordinates[0],
          "minElevationAngle": null
        },
        "polygon": null,
      }))
    ];

    return {
      areaOfInterests,
      "name": `AOI Manager ${Number(sessionStorage.getItem('aoiManagerLength'))}`,
      "type": pointDetails.length > 1 ? "Collection" : "Single"
    };
  };

  //after closing the prompt
  const handleClose = () => {
    if (draw.current && featureId) {
      draw.current.delete(featureId);
    }
    setShowPrompt(false);
    if (polyDetails.length > 0) {
      disableButton('point');
      // enableButton('polygon')
      // enableButton('trash')
      setDisableUploadButton(true)
    }
    else if (pointDetails.length > 0) {
      // enableButton('point')
      disableButton('polygon')
      // enableButton('trash')
      setDisableUploadButton(true)
    }
    else {
      enableButton('point')
      enableButton('polygon')
      disableButton('trash')
      setDisableUploadButton(false)
    }
  };

  const handleCloseEdit = () => {
    setShowPromptEdit(false);
    // toast.dismiss();
    // toast.info("GeoFeature Unchanged", { className: 'custom-toast-info' }, { toastId: '0' })
    if (!coordinates) {

      if (draw.current && featureId) {
        const featureExistsInPolyDetails = polyDetails.some(poly => poly.id === featureId);
        const featureExistsInPointDetails = pointDetails.some(point => point.id === featureId);

        if (!featureExistsInPolyDetails && !featureExistsInPointDetails) {
          draw.current.delete(featureId);
        }
      }

      if (polyDetails.length > 0) {
        disableButton('point');
        // enableButton('polygon')
        // enableButton('trash')
        setDisableUploadButton(true)
      }
      else if (pointDetails.length > 0) {
        // enableButton('point')
        disableButton('polygon')
        // enableButton('trash')
        setDisableUploadButton(true)
      }
      else {
        enableButton('point')
        enableButton('polygon')
        disableButton('trash')
        setDisableUploadButton(false)
      }
    }
  };

  //after clicking on close button of the map
  const handleButtonClick = () => {
    if (currentFeature === 'Polygon' && polyDetails.length > 0) {
      postFeatureData(mission_id, transformPolyData(polyDetails), "draw")

    }
    else if (currentFeature === 'Point' && pointDetails.length > 0) {
      postFeatureData(mission_id, transformPointData(pointDetails), "draw")
    }
    if (sessionStorage.getItem('edited') === 'true') {
      sessionStorage.setItem('edited', 'false');
      if (coordinates && currentFeature === '' && aoiFeatureData) {
        updateFeatureData(aoi_id, aoiFeatureData, "coordinatesEdit");
      }
    }
    navigate(`${process.env.PUBLIC_URL}/${mission_name}/_details/${mission_id}/Features`)
  };

  // for editing the polygon or point
  const handleUpdateAoi = async (newCoordinates, newTitle, newDescription) => {
    try {
      const aoiData = await getAoiData(aoi_id);
      const updatedAoiData = {
        ...aoiData,
        ...(aoiData.type === "Geofence"
          ? {
            polygon: newCoordinates !== undefined ? newCoordinates[0] : aoiData.polygon,
            name: newTitle !== "" ? newTitle : aoiData.name,
            description: newDescription || (aoiData?.description ?? "")
          }
          : {
            coordinates: {
              altitude: 0,
              lat: newCoordinates !== undefined ? newCoordinates[1] : aoiData.coordinates.lat,
              long: newCoordinates !== undefined ? newCoordinates[0] : aoiData.coordinates.long,
              minElevationAngle: 0
            },
            polygon: null,
            name: newTitle !== "" ? newTitle : aoiData.name,
            description: newDescription || (aoiData?.description ?? "")
          }
        )
      };

      setAoiFeatureData(updatedAoiData)

    } catch (error) {
      console.error('Error in updating and posting AOI data:', error);
    }
  };

  const customSimpleSelectCreateMode = {
    ...MapboxDraw.modes.simple_select,

    onDrag: function (state, e) {
      // Check if featureTarget exists and has a geometry property
      if (e.featureTarget && e.featureTarget.geometry && e.featureTarget.geometry.type === 'Point') {
        // Allow dragging for points
        MapboxDraw.modes.simple_select.onDrag.call(this, state, e);
      } else {
        // Do nothing (disable feature dragging)
        return;
      }
    }
  };

  const customSimpleSelectEditMode = {
    ...MapboxDraw.modes.simple_select,

    onDrag: function (state, e) {
      // Prevent dragging for all feature types (polygon and point)
      return;
    },

    onClick: function (state, e) {
      // Prevent selection on click for all feature types
      return;
    },

    onMouseMove: function (state, e) {
      // Prevent any hover behavior if desired
      return;
    },
  };


  // Function to add custom tooltip elements with unique classes
  function addCustomTooltips() {
    const mapControls = document.querySelectorAll('.mapbox-gl-draw_ctrl-draw-btn');
    mapControls.forEach((control) => {
      control.removeAttribute('title');
      const tooltip = document.createElement('span');
      if (control.classList.contains('mapbox-gl-draw_polygon')) {
        tooltip.textContent = 'Polygon';
        tooltip.className = 'polygon-tooltip'; // Unique class for Polygon
      } else if (control.classList.contains('mapbox-gl-draw_trash')) {
        tooltip.textContent = 'Delete';
        tooltip.className = 'trash-tooltip'; // Unique class for Trash
      } else if (control.classList.contains('mapbox-gl-draw_point')) {
        tooltip.textContent = 'Target Track';
        tooltip.className = 'point-tooltip'; // Unique class for Point
      }
      control.appendChild(tooltip);
    });
  }
  setTimeout(addCustomTooltips, 10);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const elements = document.querySelectorAll('.mapboxgl-ctrl-group.mapboxgl-ctrl');
      elements.forEach((element) => {
        // Override CSS visibility with !important
        element.style.setProperty('visibility', editmode ? 'hidden' : 'visible', 'important');
      });
    }, 50); // 50ms delay

    return () => clearTimeout(timeout);
  }, [editmode]);

  useEffect(() => {
    let timer;
    if (hover) {
      timer = setTimeout(() => setDelayedHover(true), 100);
    } else {
      clearTimeout(timer);
      setDelayedHover(false);
    }
    return () => clearTimeout(timer);
  }, [hover]);

  useEffect(() => {
    sessionStorage.setItem('polyDetails', JSON.stringify(polyDetails));
  }, [polyDetails])

  useEffect(() => {
    sessionStorage.setItem('pointDetails', JSON.stringify(pointDetails));
  }, [pointDetails])

  useEffect(() => {
    setPolyDetails([]);
    setPointDetails([]);
    setCurrentFeature('');
    setShowPrompt(false);
    setShowPromptEdit(false);

    mapboxgl.accessToken = "pk.eyJ1IjoiYW50YXJpcy1tYXBib3giLCJhIjoiY2x2cGNubzF4MDBveTJtb2RtNG5zMGQ2NCJ9.MkPyl-z2FXuFSyYNwP_oaw";

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [0, 0],
      zoom: 1.8,
      minZoom: 1.5
    });

    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        point: true,
      },
      modes: {
        ...MapboxDraw.modes,
        simple_select: coordinates ? customSimpleSelectEditMode : customSimpleSelectCreateMode // Use custom simple_select mode
        //Note-> this code might need in future
        // simple_select: customSimpleSelect 
      }
    });
    map.current.addControl(draw.current, "top-left");
    disableButton('trash')
    if (coordinates) {
      disableButton('polygon')
      disableButton('trash')
      disableButton('point')
    }

    const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
    const pointButton = document.querySelector('.mapbox-gl-draw_point');

    // Listen for mode changes
    map.current.on('draw.modechange', function (e) {
      const { mode } = e; // Get the current mode

      // Check if the mode is 'draw_polygon'
      if (mode === 'draw_polygon') {
        // Change the polygon button's background color
        polygonButton.style.backgroundColor = '#3D4524';
        setActiveUploadButton(false)
        // Reset the point button's background color
        pointButton.style.backgroundColor = '';
      } else if (mode === 'draw_point') {
        // Change the point button's background color
        pointButton.style.backgroundColor = '#3D4524';
        setActiveUploadButton(false)
        // Reset the polygon button's background color
        polygonButton.style.backgroundColor = '';
      } else {
        // Reset background colors when not in polygon or point mode
        polygonButton.style.backgroundColor = '';
        pointButton.style.backgroundColor = '';
        setActiveUploadButton(false)
      }
    });

    map.current.on('draw.modechange', (e) => {
      if (coordinates === undefined || coordinates) {
        if (e.mode === 'direct_select') {
          draw.current.changeMode('simple_select'); // Revert to selection mode if editing is attempted
        }
      }
    });
    //after loading the map
    map.current.on("load", () => {

      document.querySelector('.mapbox-gl-draw_polygon').id = "polygon_btn";
      document.querySelector('.mapbox-gl-draw_point').id = "point_btn";
      document.querySelector('.mapbox-gl-draw_trash').id = "trash_btn";
      // for indivisual edit button, plotting the features
      if (coordinates && coordinates.lat ? (typeof coordinates.lat === 'number' && typeof coordinates.long === 'number') : (Array.isArray(coordinates) && coordinates.length > 0)) {
        const geojson = {
          type: 'Feature',
          geometry: {
            type: coordinates.lat ? 'Point' : 'Polygon',
            coordinates: coordinates.lat ? [coordinates.long, coordinates.lat] : [coordinates],
          },
        };
        const featureId = draw.current.add(geojson);
        if (coordinates.lat) {
          draw.current.changeMode('simple_select', { featureId });
        } else {
          draw.current.changeMode('direct_select', { featureId });
        }
      }


      if (coordinates) {
        if (coordinates.lat && coordinates.long) {
          // Zoom to the point
          map.current.flyTo({
            center: [coordinates.long, coordinates.lat],
            zoom: 7, // Adjust zoom level as needed
            essential: true,
          });

          if (featureId && draw.current) {
            draw.current.changeMode('simple_select', { featureId });
          }
        } else if (Array.isArray(coordinates) && coordinates.length > 0) {
          // Validate that each coordinate is an array of valid longitude and latitude
          const validCoords = coordinates.every(
            (coord) => Array.isArray(coord) && coord.length === 2 && !isNaN(coord[0]) && !isNaN(coord[1])
          );

          if (validCoords) {
            // Zoom to the polygon
            const bounds = new mapboxgl.LngLatBounds();
            coordinates.forEach((coord) => bounds.extend(coord));

            map.current.fitBounds(bounds, {
              padding: 40, // Adjust padding as needed
              maxZoom: 8, // Set max zoom to avoid zooming in too close
              essential: true,
            });

            if (featureId && draw.current) {
              draw.current.changeMode('direct_select', { featureId });
            }
          } else {
            console.warn("Invalid coordinates in the array");
          }
        } else {
          console.warn("Coordinates are missing or invalid");
        }
      }




      showPopupOnEdit()
      //after creating feature
      map.current.on("draw.create", (event) => {
        const feature = event.features[0];
        setFeatureId(feature.id);
        setCurrentFeature(feature.geometry.type)
        setDisableUploadButton(true)
        disableButton('trash')

        //if its polygon
        if (feature.geometry.type === "Polygon") {
          setCoordie(feature.geometry.coordinates);
          const coordinates = feature.geometry.coordinates[0];
          updatePromptPositionForPolygon(coordinates)
          setShowPrompt(true);
          disableButton('point')
          disableButton('polygon')
        }

        //if its point
        if (feature.geometry.type === "Point") {
          setPointCoordie(feature.geometry.coordinates);
          updatePromptPositionForPoint(feature.geometry.coordinates)
          setShowPrompt(true);
          disableButton('point')
          disableButton('polygon')
        }
        const allFeatures1 = draw.current.getAll().features;
        sessionStorage.setItem('allfeatures', JSON.stringify(allFeatures1))
      });

      map.current.on('draw.selectionchange', function (e) {
        const selectedFeatures = e.features; // Array of selected features
        if (selectedFeatures.length > 0) {
          if (!coordinates) {
            enableButton("trash")
          }
        } else {
          disableButton("trash")
        }
      });



      map.current.on('click', (e) => {
        const featuresAtClick = map.current.queryRenderedFeatures(e.point);

        if (featuresAtClick.length) {
          // Find the feature with a matching layer ID
          const clickedFeature = featuresAtClick.find(f =>
            f.layer.id === "gl-draw-polygon-fill-inactive.cold" ||
            f.layer.id === "gl-draw-polygon-fill-active.cold" ||
            f.layer.id === "gl-draw-polygon-fill-active.hot" || f.layer.id === "gl-draw-point-inactive.cold" || f.layer.id === "gl-draw-point-active.hot" || f.layer.id === "gl-draw-point-point-stroke-inactive.cold"
          );

          if (clickedFeature && (coordinates || (JSON.parse(sessionStorage.getItem("polyDetails")).length === 1 || JSON.parse(sessionStorage.getItem("pointDetails")).length === 1))) {
            // Opne prompt after clicking with the clicked feature
            if (clickedFeature.layer.id === "gl-draw-point-inactive.cold" || clickedFeature.layer.id === "gl-draw-point-active.hot" || clickedFeature.layer.id === "gl-draw-point-point-stroke-inactive.cold") {
              if (showPrompt === false) {
                updatePromptPositionForPoint(clickedFeature.geometry.coordinates)
              }
            } else {
              //Note--> commented code might need in future
              // if (coordinates) {

              // if (coordinates.lat) {
              // draw.current.setFeatureProperty(clickedFeature.id, 'draggable', false);
              // Optionally change to static mode for points
              // draw.current.changeMode('static', { featureId: clickedFeature.properties.id });
              // } else {
              // draw.current.changeMode('simple_select', { featureId: clickedFeature.properties.id });
              // }
              // }
              if (showPrompt === false) {
                updatePromptPositionForPolygon(clickedFeature.geometry.coordinates[0])
              }
            }
            if (showPrompt === false) {
              disableButton("trash")
              setShowPromptEdit(true)
            }
          }
        }
      });

      map.current.on("draw.update", (event) => {
        setShowPrompt(false)
        const updatedFeature = event.features[0];
        setFeatureId(updatedFeature.id);
        if (!coordinates) { //when not in edit_feature
          const allFeatures = JSON.parse(sessionStorage.getItem('allfeatures')) || [];
          const polyDetails = JSON.parse(sessionStorage.getItem('polyDetails')) || [];
          const pointDetails = JSON.parse(sessionStorage.getItem('pointDetails')) || [];
          const allFeatures1 = draw.current.getAll().features;
          const featuresToDelete = allFeatures.filter(feature => {  // Find the features that are not in either polyDetails or pointDetails, and exclude the updated feature
            const notInPolyOrPointDetails = // Check if feature is not in either polyDetails or pointDetails
              !polyDetails.some(poly => poly.id === feature.id) &&
              !pointDetails.some(point => point.id === feature.id);
            return notInPolyOrPointDetails && feature.id !== updatedFeature.id; // Ensure the feature's id is not the same as updatedFeature.id
          });

          featuresToDelete.forEach(feature => { // Loop through the features to delete them from the map
            draw.current.delete(feature.id);
          });

          const updatedFeatures = allFeatures.filter(feature => // Keep feature if it's in either polyDetails or pointDetails, or if it's the updated feature
            polyDetails.some(poly => poly.id === feature.id) ||
            pointDetails.some(point => point.id === feature.id) ||
            feature.id === updatedFeature.id
          );
          sessionStorage.setItem('allfeatures', JSON.stringify(updatedFeatures));
        }
        let newCoordinates = updatedFeature.geometry.coordinates;
        let coordinates_1 = null;
        if (!coordinates) {
          disableButton('point')
          disableButton('polygon')
          disableButton('trash')
        }
        if (updatedFeature.geometry.type === 'Polygon') {
          coordinates_1 = newCoordinates[0]
          setCoordie(updatedFeature.geometry.coordinates)
          updatePromptPositionForPolygon(coordinates_1)
        } else if (updatedFeature.geometry.type === 'Point') {
          coordinates_1 = newCoordinates
          setPointCoordie(updatedFeature.geometry.coordinates)
          updatePromptPositionForPoint(updatedFeature.geometry.coordinates)
        }
        setShowPromptEdit(true)
        // map.current.off('click', 'polygon');
        setUpdatedCoordinates(newCoordinates)
      })
      //after deleting the feature
      map.current.on('draw.delete', (event) => {
        toast.error("Creation Deleted", { className: 'custom-toast-success-error align__toast__position', position: 'top-right' }, { toastId: '0' })
        setShowPrompt(false);
        setShowPromptEdit(false);
        const deletedIds = event.features.map((feature) => feature.id);
        setPolyDetails((prevPolyDetails) => {
          const updatedPolyDetails = prevPolyDetails.filter((poly) => !deletedIds.includes(poly.id));
          // Update point details
          setPointDetails((prevPointDetails) => {
            const updatedPointDetails = prevPointDetails.filter((point) => !deletedIds.includes(point.id));

            // Check combined conditions for button states
            const hasPolygons = updatedPolyDetails.length > 0;
            const hasPoints = updatedPointDetails.length > 0;

            if (hasPolygons) {
              disableButton('point')
              // enableButton('polygon')
              // enableButton('trash')
              setDisableUploadButton(true)
            }
            if (hasPoints) {
              disableButton('polygon')
              // enableButton('point')
              // enableButton('trash')
              setDisableUploadButton(true)
            }

            if (!hasPolygons && !hasPoints) {
              enableButton('point');
              enableButton('polygon');
              disableButton('trash');
              setDisableUploadButton(false)
            }
            return updatedPointDetails;
          });
          return updatedPolyDetails;
        });

      });
    });
    return () => {
      map.current.remove();
    };
  }, []);


  return (
    <div className="h-100 w-100 d-flex position-relative ">
      <div className="w-100 h-100 FeatureMap position-absolute" ref={mapContainerRef} />
      <div
        className="position-absolute"
        style={{
          position: "absolute",
          left: `${promptPosition.x + 170}px`,
          top: `${promptPosition.y + 70}px`,
          transform: "translate(-50%, -50%)",
          zIndex: 999
        }}
      >
        {showPrompt && <div className="prompt" style={{ marginBottom: "100px" }}>
          <FeaturePopup
            close={() => handleClose()}
            addTitle={(title, desc) => {
              sessionStorage.setItem("edited", 'true')
              handleData(title, desc);
              setShowPrompt(false);
              // enableButton('trash')
              if (currentFeature === 'Polygon') {
                disableButton('point')
                // enableButton("polygon")
              } else if (currentFeature == 'Point') {
                // enableButton('point')
                disableButton("polygon")
              }
            }}
            titleEdit={""}
            descriptionEdit={""}
            currentFeature={coordinates ? featuretype : currentFeature}
          />
        </div>}
        {showPromptEdit && <div className="prompt" style={{ marginBottom: "100px" }}>
          <FeaturePopup
            close={() => handleCloseEdit()}
            addTitle={(title, desc) => {
              if (aoiFeatureData?.name !== title || aoiFeatureData?.description !== desc) {
                sessionStorage.setItem("edited", 'true')
              }
              if (coordinates) {
                handleUpdateAoi(updatedCoordinates, editedTitle, editedDescription)
              }

              if (coordinates) {
                if (title !== "") {
                  setTitleInPopup(title)
                }
                if (desc !== "") {
                  setDescriptionInPopup(desc)
                }
                if (aoiFeatureData?.name !== title || aoiFeatureData?.description !== desc) {
                  const updatedAoiData = {
                    ...aoiFeatureData,
                    name: title !== "" ? title : aoiFeatureData.name,
                    description: desc || (aoiFeatureData?.description ?? "")
                  };
                  updateFeatureData(aoi_id, updatedAoiData, "nameEdit")
                }
              }
              setEditedTitle(title)
              setEditedDescription(desc);
              setShowPromptEdit(false);
              if (coordinates) {
                handleUpdateAoi(updatedCoordinates, title, desc)
              } else {
                handleData(title, desc);
              }
            }}
            titleEdit={coordinates ?
              titleInPopup
              : (currentFeature === "Point" ? pointDetails.find(detail => detail.id === featureId)?.title : currentFeature === "Polygon" ? polyDetails.find(detail => detail.id === featureId)?.title : "")}

            descriptionEdit={coordinates
              ? descriptionInPopup
              : (currentFeature === "Point"
                ? pointDetails.find(detail => detail.id === featureId)?.description
                : currentFeature === "Polygon"
                  ? polyDetails.find(detail => detail.id === featureId)?.description
                  : "")}
            currentFeature={coordinates ? featuretype : currentFeature}
          />
        </div>}
      </div>
      {!editmode &&
        <div className="uploadButtonBig">
          <button
            id="upload_btn"
            className="uploadButton"
            onClick={() => {
              setActiveUploadButton(true)
              const polygonButton = document.querySelector('.mapbox-gl-draw_polygon');
              const pointButton = document.querySelector('.mapbox-gl-draw_point');
              handleUpload();

              if (map.current && draw.current) {
                draw.current.changeMode('simple_select'); // Reset the draw mode to a non-drawing mode
              } else {
                console.error('Map or Draw instance is not initialized');
              }
              polygonButton.style.backgroundColor = '';
              pointButton.style.backgroundColor = '';
            }}

            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseLeave={() => {
              setHover(false)
            }}
            disabled={coordinates || polyDetails.length || pointDetails.length || disableUploadButton}
            style={{
              opacity: coordinates || polyDetails.length || pointDetails.length || disableUploadButton ? '0.5' : '1',
              cursor: coordinates || polyDetails.length || pointDetails.length || disableUploadButton ? 'not-allowed' : 'pointer',
            }}
          />
        </div>
      }
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the input
        accept=".zip,.kml,.topojson,.csv,.geojson"
        onChange={handleFileChange}
      />

      <button className="exitButton"
        id="exit_btn"
        onClick={() => {
          handleButtonClick();
        }}
        disabled={showPromptEdit || showPrompt}
        style={{
          color: (showPromptEdit || showPrompt) ? 'rgba(240, 240, 240, 0.5)' : 'rgba(240, 240, 240, 1)',
          cursor: (showPromptEdit || showPrompt) ? 'not-allowed' : 'pointer'
        }}
      >
        <span className={`${showPromptEdit || showPrompt ? 'exitButtonDisabled' : "exitButtonText"}`}>Exit</span>
      </button>
      <div
        id="infoDiv"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          position: 'absolute',
          top: '63px',
          right: '30px',
          display: delayedHover ? 'flex' : 'none',
          alignItems: 'center', // Align icon and text vertically centered
          gap: '8px', // Adds space between icon and text
          background: 'rgba(9,9,9,0.80)',
          zIndex: 999,
          width: "360px",
          padding: "8px 12px",
          borderRadius: "4px",
          border: "0.5px solid rgba(240, 240, 240, 0.20)"
        }}
      >
        <div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14.001C11.3137 14.001 14 11.3147 14 8.00098C14 4.68727 11.3137 2.00098 8 2.00098C4.68629 2.00098 2 4.68727 2 8.00098C2 11.3147 4.68629 14.001 8 14.001Z" stroke="#F0F0F0" stroke-opacity="0.9" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 5.33496H8.00667V5.34163H8V5.33496Z" stroke="#F0F0F0" stroke-opacity="0.9" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M8 8.00098V10.6676" stroke="#F0F0F0" stroke-opacity="0.9" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div style={{
          color: '#FFF',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          fontSize: "12px"
        }}>
          Supported file formats: Shapefile(.zip), CSV (only for target track), TopoJson, GeoJson, KML. The file size should not be more than 1MB.
        </div>
      </div>
    </div >
  );
}

export default DrawFeature;