import { useEffect, useState } from 'react';
import MissionService from '../../../service/MissionService';
import { toast } from "react-toastify";
import './toastPopup.scss';

const useFeature = () => {
    const [managerData, setManagerData] = useState([]);
    const [featuresData, setFeaturesData] = useState([]);

    //post all features
    const postFeatureData = async (id, formData, action) => {
        try {
            await MissionService.postFeature(id, formData);
            if (action === "draw") {
                toast.dismiss();
                toast.success("GeoFeature Created", { className: 'custom-toast-success align__toast__position__top', position: "top-right", }, { toastId: '0' });
                sessionStorage.setItem("updateCollection", (parseInt(sessionStorage.getItem("updateCollection")) || 0) + 1);
            } else if (action === "upload") {
                sessionStorage.setItem("updateCollection", (parseInt(sessionStorage.getItem("updateCollection")) || 0) + 1);
                sessionStorage.setItem("upload", "true")
                toast.dismiss();
                toast.success(`File Uploaded`, {className: 'custom-toast-success align__toast__position__top', position: "top-right",},{ toastId: '0' });
            }

        } catch (err) {
            sessionStorage.setItem("updateCollection", 0)
            if (action === 'draw') {
                toast.dismiss();
                toast.error(`Creation Failed`, {className: 'custom-toast-error align__toast__position__top', position: "top-right",},{ toastId: '0' });
            }

            console.log(err.message);
        }
    };
    //get all feature managers
    const getFeatureManager = async (id) => {
        try {
            const res = await MissionService.getAoiManagersByMissionId(id);
            setManagerData(res);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //after clicking on the manager get all its feature
    const getFeaturesOfManager = async (aoiManager_id) => {
        try {
            const res = await MissionService.getAllAoisByManagerId(aoiManager_id);
            setFeaturesData(res);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //delete the manager
    const deleteAoiManager = async (aoiManager_id) => {
        try {
            const response = await MissionService.deleteAoiManagerByManagerId(aoiManager_id);
            if (response && response.status === 200) {
                toast.dismiss();
                toast.success(`GeoFeatures Deleted`, {className: 'custom-toast-success-error align__toast__position__top', position: "top-right",},{ toastId: '0' });
            } else {
                toast.dismiss();
                toast.error(`GeoFeatures not Deleted`, {className: 'custom-toast-error align__toast__position__top', position: "top-right",},{ toastId: '0' });
            }
        } catch (error) {
            console.error('Error deleting AOI Manager:', error);
        }
    };

    //delete the indivisual feature
    const deleteAoi = async (aoiId) => {
        try {
            const response = await MissionService.deleteAoiById(aoiId);
            if (response && response.status === 200) {
                toast.dismiss();
                toast.success(`GeoFeature Deleted`, {className: 'custom-toast-success-error align__toast__position__top', position: "top-right"}, { toastId: '0' });
            } else {
                toast.dismiss();
                toast.success(`GeoFeature not Deleted`, {className: 'custom-toast-success align__toast__position__top', position: "top-right"}, { toastId: '0' });
            }
        } catch (error) {
            console.error('Error deleting AOI Manager:', error);
        }
    };
    //single aoi data by its id
    const getAoiData = async (aoiId) => {
        try {
            const res = await MissionService.getAoiById(aoiId);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //edit button for indivisual feature
    const updateFeatureData = async (aoiId, data, action) => {
        try {

            await MissionService.updateAoiById(aoiId, data);
            if (action === "coordinatesEdit") {
                toast.dismiss();
                toast.success(`GeoFeature Edited`, { className: 'custom-toast-success align__toast__position__top', position: "top-right", }, { toastId: '0' });
            }
            // else if (action === 'nameEdit') {
            //     toast.dismiss();
            //     toast.success(`GeoFeature Edited`, { className: 'custom-toast-success align-toast-position_edit', position: "top-right", }, { toastId: '0' });
            // }

        } catch (err) {
            console.error('Error updating feature:', err.message);
        } finally {
        }
    };
    //get all aoi by its type (eg geofence)
    const getAllAoiByType = async (misson_id, type) => {
        try {
            const res = await MissionService.getAllAoisByType(misson_id, type);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };
    //get all features by mission id
    const getAllAoiByMissionId = async (misson_id) => {
        try {
            const res = await MissionService.getAllAoisByMissionId(misson_id);
            return res.data;
        } catch (err) {
            console.log(err);
        } finally {
            console.log("data got succesfully");
        }
    };


    return {
        managerData,
        setManagerData,
        featuresData,
        setFeaturesData,
        postFeatureData,
        getFeatureManager,
        getFeaturesOfManager,
        deleteAoiManager,
        deleteAoi,
        updateFeatureData,
        getAoiData,
        getAllAoiByType,
        getAllAoiByMissionId
    };
};
export default useFeature;