//@ts-nocheck
import * as Cesium from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';

export function gs_visualization_presentation() {
    const getArrayCoordinates = (nestedArray) => {
        let resultArray = [];
        resultArray = resultArray.concat(...nestedArray);
        let finalArray = [];
        for (let i = 0; i < resultArray.length; i++) {
            finalArray.push(resultArray[i]);  // Push the current element
            if ((i % 2 === 1)) { // i >= 2 ensures we start from the 3rd element
                finalArray.push(0);  // Push 0 after every element at the specified odd index
            }
        }
        return finalArray;
    }
    const createDocument = () => {
        let document = [];
        document.push({
            "clock": {
                "multiplier": 0,
                "range": "CLAMPED",
                "step": "SYSTEM_CLOCK_MULTIPLIER"
            },
            "id": "document",
            "name": "Satellite Object",
            "version": "1.0"
        })
        return document;
    }
    const getSatFromGroup = (constellation) => {
        let satelliteArray = [];
        satelliteArray = constellation?.groups[0]?.satellites
    }
    const getAvailibility = (startTime, duration) => {
        let start = startTime * 1000
        const startTimeISO = new Date(start)
        let end = start + duration * 60000
        const endTimeISO = new Date(end)
        return {
            startTimeISO, endTimeISO
        }
    }
    function removeDataSourceByName(viewer, dataSourceName) {
        if (!viewer) return
        const dataSources = viewer.dataSources;  // Get the data sources collection
        if (!dataSources) return
        // Iterate over the data sources using a for loop
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);  // Get the data source at index i
            // Check if the data source has a name that matches the one we're looking for
            if (dataSource.name === dataSourceName) {
                dataSources.remove(dataSource);
                return dataSource;  // Exit the function after removing
            }
        }
        viewer_rerender(viewer)
    }
    function getDataSourceByName(viewer, dataSourceName) {
        const dataSources = viewer?.dataSources;  // Get the data sources collection
        // Iterate over the data sources using a for loop
        if (!dataSources) return
        for (let i = 0; i < dataSources.length; i++) {
            const dataSource = dataSources.get(i);  // Get the data source at index i

            if (dataSource.name === dataSourceName) {
                return dataSource;  // Return the removed data source
            }
        }
        return null;
    }
    const hide_show_datasource = (mm_viewer, dataSourceName) => {
        const dataSource = getDataSourceByName(mm_viewer, dataSourceName);
        if (!dataSource) return
        dataSource.show = !dataSource.show;
        viewer_rerender(mm_viewer)
    }
    const reset_viewer_position = (mm_viewer) => {
        if (!mm_viewer) return
        mm_viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(0, 0, 34000000)
        });
    }
    const getOrbitObjectAvailability = (mm_viewer, dataSourceName) => {
        const dataSource = getDataSourceByName(mm_viewer, dataSourceName);
        if (!dataSource) return
        if (Cesium.defined(dataSource) && Cesium.defined(dataSource.clock)) {
            const clock = dataSource.clock;
            const startTime = clock.startTime;
            const stopTime = clock.stopTime;
            const start = Cesium.JulianDate.toDate(startTime).toISOString();
            const stop = Cesium.JulianDate.toDate(stopTime).toISOString();
            return `${start}/${stop}`
        }
    }
    const showDataSource = (mm_viewer, dataSourceName) => {
        if (!mm_viewer) return
        const dataSource = getDataSourceByName(mm_viewer, dataSourceName);
        if (!dataSource) return
        dataSource.show = true;
        viewer_rerender(mm_viewer)
    }
    const hideDataSource = () => (mm_viewer, dataSourceName) => {
        const dataSource = getDataSourceByName(mm_viewer, dataSourceName);
        if (!dataSource) return
        dataSource.show = false;
        viewer_rerender(mm_viewer)
    }
    const getEntityById = (viewer: any, dataSourceName: string, entityId: string) => {
        const dataSource = getDataSourceByName(viewer, dataSourceName)
        if (!dataSource) return
        const entity = dataSource.entities.getById(entityId);
        return entity
    }

    const viewer_rerender = (mm_viewer) => {
        mm_viewer.scene.requestRender();
        setTimeout(() => {
            mm_viewer.scene.requestRender();
        }, 100)
    }

    const getMaxEndTime = (data) => {
        return Math.max(...data.map(item => (item.startTime * 1000) + (item.duration * 60000)));
    };
    const getMinStartTime = (data) => {
        return Math.min(...data.map(item => item.startTime * 1000));
    };

    return {
        init_default_gs: (mm_viewer) => {
            const groundStationInput = {
                name: 'GS_01',
                lat: 35.5,    // Latitude should be a number
                long: 35.5,   // Longitude should be a number
                groundStationId: 'new_default_gs'
            };
            const default_entity = {
                "billboard": {
                    "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,  // Use Cesium constants
                    "image": '/assets/un_select_gs.svg',
                    "pixelOffset": new Cesium.Cartesian2(0, 0),          // Correctly use Cartesian2
                    "show": true,
                    "verticalOrigin": Cesium.VerticalOrigin.CENTER       // Use Cesium constants
                },
                "label": {
                    "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255), // Correct RGBA to Cesium.Color
                    "font": "montserrat",
                    "horizontalOrigin": Cesium.HorizontalOrigin.LEFT,        // Use Cesium constants
                    "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),    // Correct outline RGBA
                    "outlineWidth": 2,
                    "pixelOffset": new Cesium.Cartesian2(15, 0),             // Correctly use Cartesian2
                    "show": true,
                    "style": Cesium.LabelStyle.FILL_AND_OUTLINE,             // Use Cesium constants
                    "verticalOrigin": Cesium.VerticalOrigin.CENTER,          // Use Cesium constants
                    "text": groundStationInput?.name,
                },
                "name": groundStationInput?.name,
                "position": Cesium.Cartesian3.fromDegrees(groundStationInput.lat, groundStationInput.long, 0), // Correct position to use lat/long
                "id": groundStationInput?.groundStationId
            };

            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (dataSource) {
                const entityId = 'new_default_gs';
                const entity = dataSource.entities.getById(entityId);
                if (!entity) {
                    dataSource.entities.add(default_entity);
                }
            } else {
                gs_visualization_presentation().init_all_gs([groundStationInput], mm_viewer)
            }
            viewer_rerender(mm_viewer)
        },
        update_default_gs: (groundStationInput, mm_viewer) => {
            if (mm_viewer?.dataSources) {
                const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
                if (!dataSource) return
                const entityId = "new_default_gs"; // Example entity id
                const entity = dataSource.entities.getById(entityId);
                if (!entity) return
                const newLat = Number(groundStationInput.latitude)
                const newLong = Number(groundStationInput.longitude)
                const newAltitude = 0;
                entity.label.text = groundStationInput.groundStationName
                if (entity && entity.position instanceof Cesium.ConstantPositionProperty) {
                    const newPosition = Cesium.Cartesian3.fromDegrees(newLong, newLat, newAltitude);
                    entity.position.setValue(newPosition);
                }
                viewer_rerender(mm_viewer)
            }
        },
        selected_gs: (ground_stations, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (!dataSource) return
            const all_entities = dataSource.entities

            all_entities.values.forEach(entity => {

                if (entity && entity?._id && ground_stations.includes(entity?._id)) {
                    entity.billboard.image = '/assets/select_gs.svg';
                    entity.label.show = true
                } else {
                    entity.billboard.image = '/assets/un_select_gs.svg'; // Set the new image
                    entity.label.show = false
                }
            });
            viewer_rerender(mm_viewer)
        },
        remove_default_gs: (mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'GS_Object')
            if (!dataSource) return
            const entityId = "new_default_gs"; // Example entity id
            const entity = dataSource.entities.getById(entityId);
            dataSource.entities.remove(entity)
            viewer_rerender(mm_viewer)
        },
        init_all_gs: (all_ground_stations, mm_viewer) => {
            if (!all_ground_stations.length) return
            let gsPacketList: any[] = [];
            all_ground_stations.forEach((gs_station) => {
                const packet = {
                    "billboard": {
                        "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,  // Use Cesium constants
                        "image": '/assets/un_select_gs.svg',
                        "pixelOffset": new Cesium.Cartesian2(0, 0),          // Correctly use Cartesian2
                        "show": true,
                        "verticalOrigin": Cesium.VerticalOrigin.CENTER       // Use Cesium constants
                    },
                    "label": {
                        "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255), // Correct RGBA to Cesium.Color
                        "font": "montserrat",
                        "horizontalOrigin": "LEFT",        // Use Cesium constants
                        "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),    // Correct outline RGBA
                        "outlineWidth": 2,
                        "pixelOffset": {
                            "cartesian2": [
                                15,
                                0
                            ]
                        },
                        "show": false,
                        "style": "FILL_AND_OUTLINE",             // Use Cesium constants
                        "verticalOrigin": "CENTER",          // Use Cesium constants
                        "text": gs_station?.groundStationName ? gs_station?.groundStationName : gs_station?.name,
                    },
                    "name": gs_station?.groundStationName ? gs_station?.groundStationName : gs_station?.name,
                    "position": {
                        "cartographicDegrees": [
                            gs_station?.properties?.longitude ? Number(gs_station?.properties?.longitude) : gs_station?.long,
                            gs_station?.properties?.latitude ? Number(gs_station?.properties?.latitude) : gs_station?.lat,
                            gs_station?.properties?.altitude ? Number(gs_station?.properties?.altitude) : 0
                        ]
                    },
                    "id": gs_station?.groundStationId
                };
                gsPacketList.push(packet)

            })

            const dataSource = new Cesium.CzmlDataSource();
            removeDataSourceByName(mm_viewer, 'GS_Object')
            mm_viewer && mm_viewer.dataSources?.length > 0 && mm_viewer.dataSources.add(dataSource.load([
                {
                    "clock": {
                        "multiplier": 0,
                        "range": "CLAMPED",
                        "step": "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    "id": "document",
                    "name": "GS_Object",
                    "version": "1.0"
                },
                ...gsPacketList
            ]))
            viewer_rerender(mm_viewer)
        },

        init_all_aois: (aois, mm_viewer) => {
            if (!aois.length) return
            if (aois && mm_viewer) {
                let aoisArray: any[] = []
                let targetArray: any[] = []
                aois.forEach((aoi, index) => {
                    if (aoi?.type === 'Geofence') {
                        let coordinates = getArrayCoordinates(aoi.polygon);
                        let packet = {
                            "description": "aoi",
                            "id": aoi?.areaOfInterestId,
                            "name": aoi?.name,
                            "polygon": {
                                "extrudedHeight": 0,
                                "height": 0,
                                "material": {
                                    "solidColor": {
                                        "color": {
                                            "rgba": [
                                                9, 9, 9, 25
                                            ]
                                        }
                                    }
                                },
                                "outline": true,
                                "outlineColor": {
                                    "rgba": [240, 240, 240, 50]
                                },
                                "arcType": "RHUMB",
                                "positions": {
                                    "cartographicDegrees": coordinates
                                },
                            },
                        }
                        aoisArray.push(packet)
                    } else {
                        let packet = {
                            "billboard": {
                                "horizontalOrigin": Cesium.HorizontalOrigin.CENTER,  // Use Cesium constants
                                "image": '/assets/ground_target_icon.svg',
                                "pixelOffset": new Cesium.Cartesian2(0, 0),          // Correctly use Cartesian2
                                "show": true,
                                "verticalOrigin": Cesium.VerticalOrigin.CENTER
                            },
                            "description": 'spot target',
                            "id": aoi?.areaOfInterestId,
                            "name": aoi?.name,
                            "label": {
                                "fillColor": Cesium.Color.fromBytes(255, 255, 255, 255), // Correct RGBA to Cesium.Color
                                "font": "montserrat",
                                "horizontalOrigin": "LEFT",        // Use Cesium constants
                                "outlineColor": Cesium.Color.fromBytes(0, 0, 0, 255),    // Correct outline RGBA
                                "outlineWidth": 2,
                                "pixelOffset": {
                                    "cartesian2": [
                                        15,
                                        0
                                    ]
                                },
                                "show": false,
                                "style": "FILL_AND_OUTLINE",             // Use Cesium constants
                                "verticalOrigin": "CENTER",          // Use Cesium constants
                                "text": aoi.name,
                            },
                            "position": {
                                "cartographicDegrees":
                                    [
                                        aoi?.coordinates?.long,
                                        aoi?.coordinates?.lat,
                                        0,
                                    ]
                            }
                        }
                        targetArray.push(packet)
                    }
                })

                const aoi_dataSource = new Cesium.CzmlDataSource();
                const target_dataSource = new Cesium.CzmlDataSource();
                removeDataSourceByName(mm_viewer, 'AOI_Object')
                removeDataSourceByName(mm_viewer, 'TARGET_Object')

                mm_viewer && mm_viewer.dataSources.add(aoi_dataSource.load(
                    [
                        {
                            "clock": {
                                "multiplier": 0,
                                "range": "CLAMPED",
                                "step": "SYSTEM_CLOCK_MULTIPLIER"
                            },
                            "id": "document",
                            "name": "AOI_Object",
                            "version": "1.0"
                        },
                        ...aoisArray,
                    ]
                ))
                mm_viewer && mm_viewer.dataSources.add(target_dataSource.load(
                    [
                        {
                            "clock": {
                                "multiplier": 0,
                                "range": "CLAMPED",
                                "step": "SYSTEM_CLOCK_MULTIPLIER"
                            },
                            "id": "document",
                            "name": "TARGET_Object",
                            "version": "1.0"
                        },
                        ...targetArray
                    ]
                ))
                viewer_rerender(mm_viewer)
            }
        },
        selected_aois: (aois, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'AOI_Object')
            if (!dataSource) return
            const all_entities = dataSource.entities
            all_entities.values.forEach(entity => {
                if (entity && entity?._id && aois.includes(entity?._id)) {
                    if (Cesium.defined(entity) && Cesium.defined(entity.polygon) && Cesium.defined(entity.polygon.material)) {
                        const currentMaterial = entity.polygon.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(204, 245, 78, 50))) {
                            entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(204, 245, 78, 50));
                        }
                    }

                } else {
                    if (Cesium.defined(entity) && Cesium.defined(entity.polygon) && Cesium.defined(entity.polygon.material)) {
                        const currentMaterial = entity.polygon.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(9, 9, 9, 25))) {
                            entity.polygon.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(9, 9, 9, 25));
                        }
                    }

                }
            });
            viewer_rerender(mm_viewer)
        },

        init_all_satellites: (allOrbitData, mm_viewer, dataSourceName) => {
            let satelliteData = [];
            if (allOrbitData?.length > 0) {
                let allReports: any[] = []
                allOrbitData.map(item => {
                    if (item?.orbit_report?.length > 0) {
                        allReports.push(...item?.['orbit_report'])
                    }
                });
                if (allReports.length > 0) {
                    let startTime = new Date(getMinStartTime(allReports)).toISOString().split('.')?.[0] + 'Z';
                    let endTime = new Date(getMaxEndTime(allReports)).toISOString().split('.')?.[0] + 'Z';
                    const currentTime = new Date((new Date(startTime).getTime() + new Date(endTime).getTime()) / 2).toISOString().split('.')?.[0] + 'Z';
                    let availability = `${startTime}/${endTime}`;
                    allReports.forEach(satellite => {
                        if (Object.keys(satellite)?.length > 0) {
                            const orbitalPointArray = satellite?.orbit_points;
                            if (Array.isArray(orbitalPointArray) && orbitalPointArray.length > 0) {
                                let packet = {
                                    availability: availability,
                                    billboard: {
                                        eyeOffset: { cartesian: [0, 0, 0] },
                                        horizontalOrigin: "CENTER",
                                        image: '/assets/satellite_default.svg',
                                        pixelOffset: { cartesian2: [0, 0] },
                                        scale: 0.75,
                                        show: true,
                                        verticalOrigin: "CENTER"
                                    },
                                    description: "orbit",
                                    id: satellite?.satelliteId,
                                    path: {
                                        leadTime: [{ number: 5000 }],
                                        width: 1,
                                        material: {
                                            solidColor: {
                                                color: { rgba: [138, 138, 138, 60] } // Semi-transparent color
                                            }
                                        },
                                        resolution: 10,
                                        show: [{ boolean: true, interval: `${startTime}/${endTime}` }],
                                        trailTime: [{ number: 5000 }]
                                    },
                                    position: {
                                        cartographicDegrees: orbitalPointArray, // Ensure this is in [lon, lat, height] format
                                        epoch: availability.split('/')[0]
                                    },
                                    label: {
                                        fillColor: Cesium.Color.fromBytes(255, 255, 255, 255),
                                        font: 'montserrat',
                                        horizontalOrigin: 'LEFT',
                                        outlineColor: Cesium.Color.fromBytes(0, 0, 0, 255),
                                        outlineWidth: 2,
                                        pixelOffset: {
                                            cartesian2: [
                                                10,
                                                0
                                            ]
                                        },
                                        show: true,
                                        style: 'FILL_AND_OUTLINE',
                                        verticalOrigin: 'CENTER',
                                        text: '',
                                    },
                                };
                                satelliteData.push(packet);
                            } else {
                                console.warn(`Invalid orbital points for satellite ID: ${satellite?.satelliteId}`);
                            }
                        }
                    });
                    const dataSource = new Cesium.CzmlDataSource();
                    removeDataSourceByName(mm_viewer, dataSourceName);
                    mm_viewer.dataSources.add(dataSource.load([
                        {
                            clock: {
                                interval: availability,
                                currentTime: availability.split('/')[0],
                                multiplier: 0,
                                range: "CLAMPED",
                                step: "SYSTEM_CLOCK_MULTIPLIER"
                            },
                            id: "document",
                            name: dataSourceName,
                            version: "1.0"
                        },
                        ...satelliteData
                    ]));
                }
            } else {
                removeDataSourceByName(mm_viewer, dataSourceName);
                mm_viewer.scene.requestRender();
            }
            viewer_rerender(mm_viewer)
        },

        selected_satellite: (satellite, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'Orbit_Object')
            if (!dataSource) return
            const all_entities = dataSource && dataSource.entities
            all_entities && all_entities.values.forEach(entity => {
                if (entity && entity?._id && satellite?.['id'] === entity?._id) {
                    if (Cesium.defined(entity) && Cesium.defined(entity.path) && Cesium.defined(entity.path.material)) {
                        const currentMaterial = entity.path.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(204, 245, 78, 100))) {
                            entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(204, 245, 78, 100));
                        }
                        if (Cesium.defined(entity.label)) {
                            entity.label.text = satellite.name;
                        }
                    }

                } else {
                    if (Cesium.defined(entity) && Cesium.defined(entity.path) && Cesium.defined(entity.path.material)) {
                        const currentMaterial = entity.path.material.color;
                        if (!Cesium.Color.equals(currentMaterial.getValue(Cesium.JulianDate.now()), Cesium.Color.fromBytes(138, 138, 138, 60))) {
                            entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(138, 138, 138, 60));
                        }
                        if (Cesium.defined(entity.label)) {
                            entity.label.text = '';
                        }
                    }

                }
            });
            viewer_rerender(mm_viewer)
        },

        strip_mapping: (coverageAnalysisReport, mm_viewer, orbits) => {
            hideDataSource(mm_viewer, 'GS_Object')
            hideDataSource(mm_viewer, 'Orbit_Object')
            let availibility;
            let zoomInPoint;
            let coverageAreaBody = []
            coverageAnalysisReport?.areaOfInterests.map(aoi => {
                aoi?.visualisationData?.map((item, index) => {
                    zoomInPoint = { long: item[0][0], lat: item[0][1], alt: 0 }
                    let coverage = {
                        "description": "area of interest - " + index,
                        "id": "Facility/aoi/" + index,
                        "name": "strip" + index,
                        "polygon": {
                            "extrudedHeight": 0,
                            "height": 0,
                            "material": {
                                "solidColor": {
                                    "color": {
                                        "rgba": [240, 240, 240, 100]
                                    }
                                }
                            },
                            "arcType": "RHUMB",
                            "positions": {
                                "cartographicDegrees": [
                                    item[0][0], item[0][1], 0,
                                    item[1][0], item[1][1], 0,
                                    item[2][0], item[2][1], 0,
                                    item[3][0], item[3][1], 0,
                                ]
                            }
                        }
                    }
                    coverageAreaBody.push(coverage)
                })
            })
            if (orbits?.orbitData?.length > 0) {
                orbits.orbitData.forEach(satellite => {
                    let satAvailability = getAvailibility(satellite.startTime, satellite.duration);
                    let startTime = satAvailability.startTimeISO.toISOString();
                    let endTime = satAvailability.endTimeISO.toISOString();
                    let satAvailabilityy = `${startTime}/${endTime}`;
                    availibility = satAvailabilityy;
                    const orbitalPointArray = satellite?.orbitPoints;
                    let packet = {
                        availability: satAvailabilityy,
                        billboard: {
                            eyeOffset: { cartesian: [0, 0, 0] },
                            horizontalOrigin: "CENTER",
                            image: '/assets/satellite_default.svg',
                            pixelOffset: { cartesian2: [0, 0] },
                            scale: 0.75,
                            show: true,
                            verticalOrigin: "CENTER"
                        },
                        description: "orbit",
                        id: satellite?.id,
                        path: {
                            leadTime: [{ number: 5000 }],
                            width: 1,
                            material: {
                                solidColor: {
                                    color: { rgba: [204, 245, 78, 100] } // Semi-transparent color
                                }
                            },
                            resolution: 10,
                            show: [{ boolean: true, interval: `${startTime}/${endTime}` }],
                            trailTime: [{ number: 5000 }]
                        },
                        position: {
                            cartographicDegrees: orbitalPointArray, // Ensure this is in [lon, lat, height] format
                            epoch: satAvailabilityy.split('/')[0]
                        }
                    };
                    coverageAreaBody.push(packet)
                })
            }


            removeDataSourceByName(mm_viewer, 'Strip_Orbit')
            mm_viewer?.dataSources?.add(Cesium.CzmlDataSource.load([{
                "clock": {
                    interval: availibility,
                    "multiplier": 0,
                    "range": "LOOP_STOP",
                    "step": "SYSTEM_CLOCK_MULTIPLIER"
                },
                "id": "document",
                "name": "Strip_Orbit",
                "version": "1.0"
            }, ...coverageAreaBody])).then(() => {
                if (zoomInPoint) {
                    mm_viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(zoomInPoint.long, zoomInPoint.lat, 10000000)
                    });
                }
            });
            viewer_rerender(mm_viewer)
        },
        remove_strip_mapping: (mm_viewer) => {
            hide_show_datasource(mm_viewer, 'GS_Object')
            hide_show_datasource(mm_viewer, 'Orbit_Object')

            removeDataSourceByName(mm_viewer, 'Coverage_orbit')

            removeDataSourceByName(mm_viewer, 'Strip_Orbit')
            reset_viewer_position(mm_viewer)
            viewer_rerender(mm_viewer)
        },

        remove_entity_by_id: (entityId, dataSourceName, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, dataSourceName)
            if (!dataSource) return
            const entity = dataSource.entities.getById(entityId);
            dataSource.entities.remove(entity)
            viewer_rerender(mm_viewer)
        },
        init_orbit_for_gs_analysis: (mm_viewer, orbits, dataSourceName) => {

            hideDataSource(mm_viewer, "AOI_Object")
            hideDataSource(mm_viewer, "TARGET_Object")

            let satelliteData = [];
            let availibility;
            if (orbits?.length > 0) {
                orbits.forEach(satellite => {
                    availibility = satellite?.availability;
                    const orbitalPointArray = satellite?.satOrbitPoint;

                    let packet = {
                        availability: availibility,
                        billboard: {
                            eyeOffset: { cartesian: [0, 0, 0] },
                            horizontalOrigin: "CENTER",
                            image: '/assets/satellite_default.svg',
                            pixelOffset: { cartesian2: [0, 0] },
                            scale: 0.75,
                            show: true,
                            verticalOrigin: "CENTER"
                        },
                        description: "orbit",
                        id: satellite?.satelliteId,
                        path: {
                            leadTime: [{ number: 5000 }],
                            width: 1,
                            material: {
                                solidColor: {
                                    color: { rgba: [204, 245, 78, 100] } // Semi-transparent color
                                }
                            },
                            resolution: 10,
                            show: [{ boolean: true, interval: availibility }],
                            trailTime: [{ number: 5000 }]
                        },
                        position: {
                            cartographicDegrees: orbitalPointArray, // Ensure this is in [lon, lat, height] format
                            epoch: availibility.split('/')[0]
                        }
                    };
                    satelliteData.push(packet)
                })
            }
            let coverageCzml = [
                {
                    clock: {
                        interval: availibility,
                        multiplier: 0,
                        range: "CLAMPED",
                        step: "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    id: "document",
                    name: dataSourceName,
                    version: "1.0"
                },
                ...satelliteData
            ]
            const dataSource = new Cesium.CzmlDataSource();
            removeDataSourceByName(mm_viewer, dataSourceName);
            mm_viewer.dataSources.add(dataSource.load([
                {
                    clock: {
                        interval: availibility,
                        multiplier: 0,
                        range: "CLAMPED",
                        step: "SYSTEM_CLOCK_MULTIPLIER"
                    },
                    id: "document",
                    name: dataSourceName,
                    version: "1.0"
                },
                ...satelliteData
            ]));
            viewer_rerender(mm_viewer)
        },

        reset_default_view: (mm_viewer) => {
            if (!mm_viewer) return
            removeDataSourceByName(mm_viewer, 'Coverage_orbit')
            removeDataSourceByName(mm_viewer, 'Strip_Orbit')
            removeDataSourceByName(mm_viewer, 'GS_ORBIT')

            showDataSource(mm_viewer, 'GS_Object')
            showDataSource(mm_viewer, 'Orbit_Object')
            showDataSource(mm_viewer, "AOI_Object")
            showDataSource(mm_viewer, "TARGET_Object")
            reset_viewer_position(mm_viewer)
            viewer_rerender(mm_viewer)
        },
        selected_target: (selected_target, mm_viewer) => {
            const dataSource = getDataSourceByName(mm_viewer, 'TARGET_Object')
            if (!dataSource) return
            const all_entities = dataSource.entities
            all_entities.values.forEach(entity => {
                if (Cesium.defined(entity.billboard)) {
                    if (selected_target.includes(entity._id)) {
                        entity.billboard.image = '/assets/selected_ground_target_icon.svg';
                    } else {
                        entity.billboard.image = '/assets/ground_target_icon.svg';
                    }
                }
            });
            viewer_rerender(mm_viewer)
        },
        highlightSatellite: (sat_array, mm_viewer) => {
            if (!Array.isArray(sat_array)) {
                sat_array = [sat_array];
            }
            const dataSource = getDataSourceByName(mm_viewer, 'Orbit_Object');
            if (!dataSource) return;
            const all_entities = dataSource.entities;
            const satelliteDetails = sat_array.map(sat => {
                return { id: sat.id, name: sat.name };
            });
            const satelliteIds = satelliteDetails.map(sat => sat.id);
            all_entities.values.forEach(entity => {
                if (Cesium.defined(entity.path)) {
                    if (satelliteIds.includes(entity.id)) {
                        entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(204, 245, 78, 255)); // Bright yellow-green
                        const satDetail = satelliteDetails.find(sat => sat.id === entity.id);
                        if (Cesium.defined(entity.label)) {
                            entity.label.text = satDetail ? satDetail.name : '';
                        }
                    } else {
                        entity.path.material = new Cesium.ColorMaterialProperty(Cesium.Color.fromBytes(138, 138, 138, 150)); // Dull gray
                        if (Cesium.defined(entity.label)) {
                            entity.label.text = '';
                        }
                    }
                }
            });
            viewer_rerender(mm_viewer);
        }
    }
}       